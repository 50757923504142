import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { logoutt, updateInstallerDetails } from "../redux/Actions/user.action";
import { post } from "../services/API";

const AuthContext = createContext({
    user: undefined,
    loading: false,
    error: undefined,
    isAuthenticated: false,
    login: (userType, values) => { },
    signUp: (body, onSuccess, onError) => { },
    logout: (userType) => { },
    loginWithSocial: (userType, val) => { },
    updateUser: (newUserData) => { },
});

export function AuthProvider({ children }) {
    const [user, setUser] = useState();
    const [error, setError] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (error) setError(undefined);
    }, [location.pathname]);
    //
    useEffect(() => {
        const checkUserAuthentication = async () => {
            setLoading(true);

            try {
                axios.defaults.withCredentials = true;
                var instance = axios.create({
                    withCredentials: true
                });
                const res = await instance.get(`${process.env.REACT_APP_SERVER_URL}/auth/checkUser`);

                if (res.data.error) {
                    setUser(false);
                    setIsAuthenticated(false);
                } else {
                    setUser(res.data.user);
                    localStorage.setItem('token', res.data.token);
                    if (res.data.user.mcs_number) {
                        dispatch(updateInstallerDetails(res.data.user))
                    }
                    setIsAuthenticated(true);
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                setIsAuthenticated(false);

            } finally {
                setLoading(false);
            }
        };

        checkUserAuthentication();
    }, []);


    async function login(userType, values, onSuccess, onError) {
        setLoading(true);
        try {
            const res = await post("auth", `login/${userType}`, values);
            if (res.data) {
                setUser(res.data.user)
                localStorage.setItem('token', res.data.token);
                setIsAuthenticated(true)
                setError(undefined);
                onSuccess('login successful', 'success')
                setLoading(false)

            }
            if (res.error) {
                res.error.message.add_data ? onError(res.error.message.message, res.error.message.add_data) :
                    onError(res.error.message);


            }
        } catch (error) {
            setError(error);
            console.error("An error occurred:", error);
            setLoading(false)
        } finally {
            setLoading(false);
        }
    }

    const loginWithSocial = async (userType, val) => {

        if (val === 'google') {
            window.open(
                `${process.env.REACT_APP_SERVER_URL}/auth/google/${userType}`,
                "_self"
            )
        }
        if (val === 'facebook') {
            window.open(
                `${process.env.REACT_APP_SERVER_URL}/auth/facebook`,
                "_self"
            )
        }
    };

    async function signUp(body, onSuccess, onError) {
        try {
            const res = await post("auth", "register", body);
            if (res.data) {
                setLoading(false)
                onSuccess(
                  'Registration successful. Please verify your email and sign in now.',
                  'success'
                );
            }
            if (res.error) {
                setError(true);
                setLoading(false)
                onError(res.error.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
            setLoading(false)
            onError(error.message);
        }
        finally {
            setLoading(false);
        }
    }

    async function logout(userType) {
        localStorage.clear()
        try {
            axios.defaults.withCredentials = true
            var instance = axios.create({
                withCredentials: true
            });
            const res = await instance(`${process.env.REACT_APP_SERVER_URL}/auth/logout`,);
            if (res.data) {

                setUser(undefined)
                navigate(`/${userType}-auth`)
                dispatch(logoutt())
                localStorage.clear()

            }
        } catch (error) {
            setError(error)
        }
    }
    function updateUser(updatedUser) {
        setUser(updatedUser);
    }

    const memoedValue = useMemo(
        () => ({
            user,
            loading,
            error,
            isAuthenticated,
            login,
            signUp,
            logout,
            loginWithSocial,
            updateUser
        }),
        [user, loading, error, isAuthenticated]
    );

    return (
        <AuthContext.Provider value={memoedValue}>
            {children}
        </AuthContext.Provider>
    );
}

export default function useAuth() {
    return useContext(AuthContext);
}
