import { GET_Form_DATA_SUCCESS, GET_Form_DATA_PENDING, GET_Form_DATA_ERROR,SET_AWS_FETCH,SET_CURRENT_QUESTION_INDEX, SET_AREA_OPERATION } from "../Types/forms.type"

// Define the action creator function to set the form value
export const setFormValue = (type, value) => {
  // Return an object representing the action
  return {
    type: type,
    payload: value, // Set the payload to the form object
  } 
} 

export const getFormdataPending = () => {
  // Return an object representing the action
  return {
    type: GET_Form_DATA_PENDING,
  } 
}
  export const getFormdataSuccess = (formData,formId) => {
    // Return an object representing the action
    return {
      type: GET_Form_DATA_SUCCESS,
      formData: formData,
      formId: formId // Set the payload to the form object
    } 
  }
    export const getFormdataError = (value) => {
      // Return an object representing the action
      return {
        type: GET_Form_DATA_ERROR,
        payload: value, // Set the payload to the form object
      } 
    
}
export const setAWSfetch = (value) => {
  // Return an object representing the action
  return {
    type: SET_AWS_FETCH,
    payload: value, 
  } 

}
export const setCurrentQuestionIndex = (value) => {
  // Return an object representing the action
  return {
    type: SET_CURRENT_QUESTION_INDEX,
    payload: value, 
  } 

}

export const setAreaOperation = (value) => {
  return {
    type: SET_AREA_OPERATION,
    payload: value, 
  } 

}
