// set form data
export const SET_Form_DATA ='SET_Form_DATA' 

export const GET_Form_DATA_SUCCESS ='GET_Form_DATA_SUCCESS' 

export const GET_Form_DATA_PENDING ='GET_Form_DATA_PENDING' 

export const GET_Form_DATA_ERROR ='GET_Form_DATA_ERROR' 


// address lookup form
export const SET_FINAL_ADDRESS ='SET_FINAL_ADDRESS' 

// heatpump Assessment form
export const SET_HP_ASSESMENT_Form ='SET_HP_ASSESMENT_Form' 
// carousel
export const SET_AWS_FETCH ='SET_AWS_FETCH' 
// current index of question 
export const SET_CURRENT_QUESTION_INDEX ='SET_CURRENT_QUESTION_INDEX' 

//True or false if thermly operates in the area
export const SET_AREA_OPERATION ='SET_AREA_OPERATION' 


