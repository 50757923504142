import "./AppLayout.styles.css"
import AppHeader from "./AppHeader.component";
import AppFooter from "./AppFooter.component";
export default function AppLayout({ menu, installer, children, withLogin }) {

    return (
        <>
            <div className="app-container">
                <div className="app-content-wrapper">
                    <div className="app-header-border-container">
                        <AppHeader menu={menu} withLogin={withLogin} installer={installer} />
                    </div>
                    {children}
                </div>
                <AppFooter />

            </div>
        </>

    )
};