import { createTheme  } from '@mui/material/styles';

export const customMuiTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#14596b',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#f26548',
            contrastText: '#ffffff',
        },
        white: {
            main: '#ffffff',
            contrastText: '#ffffff',
        },
        background: {
            default: '#EAF4F2',
        },
    },
      components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#b2b2b2', // Set the color you want for the Divider here
                },
            },
        },
    },
});