import { CallApi } from "../utils/APIutil";


let serverUrl = process.env.REACT_APP_SERVER_URL;
console.log('process.env.REACT_APP_SERVER_URL', process.env.REACT_APP_SERVER_URL);

export const getAll = async (endpoint, parameter) => {
    const token = localStorage.getItem('token');
    const axiosConfig = {
        url: `${serverUrl}/${endpoint}/${parameter}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            'authorization': `Bearer ${token}`,
        },
    }
    return await CallApi(axiosConfig);
};

export const getUsers =  async (endpoint, getAccessTokenSilently) => {
    const token = localStorage.getItem('token');
    const axiosConfig = {
        url: `${serverUrl}/${endpoint}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    }

    return await CallApi(axiosConfig)
}

export const getSingle = async (endpoint, parameter) => {
    const token = localStorage.getItem('token');
    const axiosConfig = {
        url: `${serverUrl}/${endpoint}/${parameter}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            'authorization': `Bearer ${token}`,
        },
    };
    return await CallApi(axiosConfig);
};


export const post = async (endpoint, parameters, object, accessToken) => {
    const token = localStorage.getItem('token');
    const axiosConfig = {
        url: `${serverUrl}/${endpoint}/${parameters}`,
        method: "POST",
        headers: {
            'authorization': `Bearer ${token}`,
            "content-type": "application/json",
        },
        data: object,
    };
    return await CallApi(axiosConfig);
};
export const binaryPost = async (endpoint, parameters, object, accessToken) => {
    const token = localStorage.getItem('token');
    const axiosConfig = {
        url: `${serverUrl}/${endpoint}/${parameters}`,
        method: "POST",
        headers: {
            /* Authorization: `Bearer ${accessToken}`, */
            'content-type': 'multipart/form-data',
        },
        data: object,
    };
    return await CallApi(axiosConfig);
};
export const Filepost = async (endpoint, parameters, object, accessToken) => {
    const token = localStorage.getItem('token');
    const axiosConfig = {
        url: `${serverUrl}/${endpoint}/${parameters}`,
        method: "POST",
        headers: {
             Authorization: `Bearer ${token}`, 
            'content-type': 'multipart/form-data',
        },
        data: object,
    };
    return await CallApi(axiosConfig);
};