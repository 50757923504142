import { useEffect, useState } from "react";
import "./RadioGroup.style.css";

export default function CustomRadioGroup(props) {
  const {
    question,
    onChange,
    onBlur,
    error,
    helperText,
    formikProps,
    inputStyles,
    form_disabled,
    ...rest
  } = props;
  //const [selectedOption, setSelectedOption] = useState(formikProps.values[question.id]); 
  const [selectedOption, setSelectedOption] = useState(question.value); 
  
  const handleOnChange = (event) => {

    const { value } = event.target;

    setSelectedOption(value);
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <div className="radioGroup">
      <div className="radioGroupContainer">
        {/* radio buttons group */}
        {question.options && question.options.map((option) => (
          <label key={`radio${option.option_value}`} className="radioLabel">
            <input
              type="radio"
              className={`radioInput ${form_disabled ? 'disabled' : ''}`}
              id={option.id}
              name={question.id}
              onBlur={onBlur}
              disabled={form_disabled}
              value={option.option_value}
              checked={selectedOption === option.option_value}
              onChange={handleOnChange}
              style={{
                backgroundColor: selectedOption === option.option_value ? inputStyles : "", // Use the prop for checked state
                border: selectedOption === option.option_value ? "" : '1px solid rgba(222, 221, 219, 1)',
              }}
              {...rest}
            />
            {/* radio label */}
            <span key={`label${option.option_value}`} className={`${selectedOption === option.option_value? "white-font":'emerald-font'} text-large radioLabel-text`}>
              {option.option_label}
            </span>
          </label>
        ))}
      </div>
      {/* errors */}
      <label className="text-small orange-font"> {helperText} </label>
    </div>
  );
}
