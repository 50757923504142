
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Customcamera from "./Camera.component";
import { getSingle, post } from "../../../../../services/API";
import useAuth from "../../../../../auth/AuthProvider"
import axios from "axios";

import "./Photoupload.style.css";


export default function CustomUpload(props) {
  const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent) || window.innerWidth <= 768;
  const { question, helperText, error, formikProps, onChange, autoSave,form_disabled,type } = props;
  const { validation } = question;
	const { user, aerror, login, aloading, signUp, logout } = useAuth();

  const maxPhotos = parseInt(validation.find((val) => val.validation_type === "max")?.validation_parameter, 10) || 1;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [displayPhotoField, setDisplayPhotoField] = useState((isMobile && autoSave) ? false : true);
  const journey = useSelector((state) => state.userReducer.journey_details);
  const installer_id = useSelector((state) => state.userReducer.installer_details?.installer_id)
  const [fileTypeError, setfileTypeError] = useState("");
  // handle file change
  const handleFileChange = async (event) => {
    const files = event.target.files;
    //  files to check with allowed extensions
    const isValidFile = validFile(files)
    if(isValidFile){
      if (maxPhotos === 1) {
       await  handleUploadClick( [...files])
        setSelectedFiles([...files]);
        formikProps && formikProps.setFieldValue(question.id, [...files]);
      
     } else {
      await handleUploadClick([...selectedFiles, ...files])
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        formikProps && formikProps.setFieldValue(question.id, [...selectedFiles, ...files]);
     }
    }

  };

  // handle drop
   const handleDrop = async (event) => {
    event.preventDefault();
 
      const files = event.dataTransfer.files;
      if (maxPhotos === 1) {
        await handleUploadClick([...selectedFiles, ...files])
        setSelectedFiles([...files]);
        formikProps.setFieldValue(question.id, [...files]);

      } else {
        await handleUploadClick([...selectedFiles, ...files])
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        formikProps.setFieldValue(question.id, [...selectedFiles, ...files]);

      }
    
    
  };

  // handle drag functionality
 const handleDragOver = async (event) => {
    event.preventDefault();
      const files = event.dataTransfer.files;
      if (maxPhotos === 1) {
        await handleUploadClick([...selectedFiles, ...files])
        setSelectedFiles([...files]);
        formikProps.setFieldValue(question.id, [...files]);
      } else {
        await handleUploadClick([...selectedFiles, ...files])
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        formikProps.setFieldValue(question.id, [...selectedFiles, ...files]);
      }
    
   
  };
  // handle file upload
  const handleUploadClick = async (files) => {
    // event.preventDefault();
    if (files.length) {
      const uploadPromises = files.map(async (file) => {  
        const uploadUrl = await getSIgnedUrl(`${question.id}${file.name}`, file.type, file.name);
        try {
          const response = await fetch(uploadUrl.data, {
            method: 'PUT',
            body: file,
          }
          );

        return response
        } 
        catch (error) {
          console.error('Error uploading image:', error.message);
        }
      })
      // Wait for all file upload promises to resolve
      const uploadResults = await Promise.all(uploadPromises);
      // Check if all uploads were successful
      const allUploadsSuccessful = uploadResults.every((result) => result.ok === true);
      if (allUploadsSuccessful) {
        // After all uploads are successful, update formik and onChange for each file
        const uploadedFileKeys = files.map((file) => file.name);
        // formikProps.setFieldValue(question.id, [selectedFiles]);
        // Call onChange with the array of keys if there are more than one, else with a single key
        if (uploadedFileKeys.length > 1) {
          onChange([uploadedFileKeys]);
        } else {
          onChange([uploadedFileKeys[0]]);
        }

        setUploadSuccess(true);
      } else {
        console.error("Some files failed to upload.");
      }
    }

  };

  // handle remove
  const handleRemovePhoto = async (event, index) => {
    event.stopPropagation();
    const isResident = props.installer ? 'false' : 'true';
    const obj={
      journey_id : journey?.journey_id,
      resident_id: journey?.resident_id,
  }
      if (maxPhotos === 1) {
        const imageToDelete = JSON.parse(question.value);
        const res = await  post('form', `deleteImage/${isResident}/${question.id}${selectedFiles[0].name}`, obj)
        if (res.data) {
          setSelectedFiles([]);
          formikProps && formikProps.setFieldValue(question.id, null);
          setUploadSuccess(false);
          setDisplayPhotoField((isMobile && autoSave) ? false : true);
          autoSaveFile()
        }
      
      }
      else {
        const remainingFiles = selectedFiles.filter((_, i) => i !== index)
        const UploadFiles = remainingFiles.length > 0 ? [remainingFiles] : null
        const uploadedFileKeys = remainingFiles.map((file) => file.name);
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        const imageToDelete = selectedFiles.filter((_, i) => i === index);
        const res = await  getSingle('form', `deleteImage/${isResident}/${question.id}${imageToDelete[0].name}`)
         if (res.data){
       //   Clear the form field value
           formikProps && formikProps.setFieldValue(question.id, UploadFiles);
       setUploadSuccess(false);
       setDisplayPhotoField((isMobile && autoSave) ? false : true);
       autoSaveFile(uploadedFileKeys)
         }
   
      }
    
  //   else{
  //     if(maxPhotos===1){
  //       setSelectedFiles([]);
  //       formikProps.setFieldValue(question.id, []);
  //     }
  //     else{
  //       setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  //       formikProps.setFieldValue(question.id, (prevFiles) => prevFiles.filter((_, i) => i !== index));

  //     }
  //     setUploadSuccess(false);

  //  }
 
  };

  const autoSaveFile = (uploadedFileKeys) => {
    if (question.value) {
      maxPhotos === 1 ? onChange(null) : onChange(uploadedFileKeys.length > 0 ? [uploadedFileKeys] : null)
    }
  }
  const validFile = (files) => {
    // Filter files to only keep those with allowed extensions
    const allowedExtensions = type === 'file' ? [".pdf", ".doc", ".docx", ".txt", ".csv", ".xls", ".xlsx",".xlsm", "xlx",".ppt", ".pptx"] :[".jpg", ".jpeg", ".png"];
    const invalidFiles = Array.from(files).filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return !allowedExtensions.includes("." + extension);
    });
  
    if (invalidFiles.length > 0) {
      setfileTypeError(type === 'file' ?  "Please upload only valid file types (pdf, doc, docx, txt, csv, xls, xlsx, .xlsm, xlx, ppt, pptx)." : "Please upload only valid file types (jpg, jpeg, png).");
      return false; // Return false when there's an issue
    }
    
    if (maxPhotos > 1) {
      const fileNames = selectedFiles.map((file) => file.name);
      const duplicateFileNames = Array.from(files).some((newFile) => fileNames.includes(newFile.name));
  
      if (duplicateFileNames) {
        // Display an error message or take other appropriate actions
        setfileTypeError('Please upload new file, it appears that you tried to upload  more than one files with the same name.');
        return false; // Return false when there's an issue
      }
    }
  
    setfileTypeError(null);
    return true; // Return true when everything is valid
  }
  

  // open file browser on click
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const element = document.createElement("input");
    element.type = "file";
    element.accept = type === 'file' ? ".pdf, .doc, .docx, .txt, .csv, .xls, .xlsx, .xlsm, xlx, .ppt, .pptx" : ".jpg, .jpeg, .png";
    element.multiple = maxPhotos > 1 ? true : false;
    element.onchange = handleFileChange;
    element.click();
  };

  // fetch image url form AWS
  async function fetchImageFromS3(value) {
    const obj={
      journey_id : journey?.journey_id,
      resident_id: journey?.resident_id,
      fileName: `${question.id}${value}`
  }
    try {
      const isResident = props.installer ? 'false' : 'true';
      const res = await post('form', `getResidentImage/${isResident}/${isResident}`, obj);
      return res.data
    } catch (error) {
      console.error("Error fetching image from S3:", error);
      throw error;
    }
  }


  // fetch images from AWS
  async function fetchExistingImages() {
    axios.defaults.withCredentials = true;

    const imageArray = JSON.parse(question.value);
  
    if (maxPhotos > 1) {
      // Handle array values
      const files = Array.isArray(imageArray[0]) ? imageArray[0] : imageArray;
      for (const element of files) {
        const imageUrl = await fetchImageFromS3(element);
        const response = await fetch(imageUrl);
        const imageBlob = await response.blob();
        const imageFile = new File([imageBlob], `${element}`, { type: "image/jpeg" });
        setSelectedFiles((prevFiles) => {
          const newFiles = [...prevFiles, imageFile];
          formikProps.setFieldValue(question.id, newFiles);
          return newFiles;
        });
        setUploadSuccess(true);
      }
    } else {
      // Handle single value
      const imageUrl = await fetchImageFromS3(imageArray[0]);
      const response = await fetch(imageUrl);
      const imageBlob = await response.blob();
      const imageFile = new File([imageBlob], `${imageArray[0]}`, { type: "image/jpeg" });
      setSelectedFiles([imageFile]);
      formikProps && formikProps.setFieldValue(question.id, [imageFile]);
      setUploadSuccess(true);
    }
  }
  
  useEffect(() => {
    if (question.value) {
      fetchExistingImages();
      setDisplayPhotoField(true)
    }
    // eslint-disable-next-line
  }, []);
  const getSIgnedUrl = async (fileName, fileType, fileExtension) => {
    axios.defaults.withCredentials = true;
    const obj={
      journey_id : journey?.journey_id,
      resident_id: journey?.resident_id,
  }
    const isResident = props.installer ? 'false' : 'true';
    const res = await post('form', `getSignedUrl/${isResident}/${fileName}/${fileType}`, obj);
    return res;
};

 
  return (
    <div className={`photo-container ${form_disabled ? 'disabled' : ''}`}>
      {/* file upload for desktop */}
      {displayPhotoField && (
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={handleClick}
          className="photo-field"
          id={question.id}>
          <div className="upload-area">
            <div>
              {selectedFiles.length ? (
                <div className="image-list">
                  {selectedFiles.map((file, index) => (
                    <div key={index} className="image-item">
                      {type !== 'file' && <img src={URL.createObjectURL(file)} alt="Selected" />}
                      <div>
                        <p className="text-small grey-font">{file.name}</p>
                      </div>
                      <div className="flex-row">
                        <CancelIcon
                          className="cross-button"
                          onClick={(event) => handleRemovePhoto(event, index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-small grey-font">Drag and drop or click to select file(s)</p>
              )}
              {selectedFiles.length < maxPhotos && (
                <button className="photo-button" onClick={handleClick}>
                  {maxPhotos > 1 ? (
                    selectedFiles.length > 0 && selectedFiles.length < maxPhotos ? (
                      <>
                        <AddCircleIcon /> <span>Add</span>
                      </>
                    ) : (
                      <>
                        <FileUploadIcon />
                        <span className="text-small">&nbsp;Select</span>
                      </>
                    )
                  ) : (
                    <>
                      <FileUploadIcon /> <span className="text-small">&nbsp;Select</span>
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {/* upload button */}
      {/* {selectedFiles.length > 0 && !uploadSuccess && autoSave && !fileTypeError && (
        <CustomButton label="Upload" cssType="green" onClick={handleUploadClick} />
      )} */}

      {/* file upload for mobile */}
      {isMobile && !selectedFiles.length && autoSave && !displayPhotoField && (
        <Customcamera
          id={question.id}
          name={question.id}
          handleFileChange={handleFileChange}
          setDisplayPhotoField={setDisplayPhotoField}
          type={type}
        />
      )}
      {/* success message */}
      {uploadSuccess && <p className="text-small emerald-font">Files uploaded successfully!</p>}
      {/* error message */}
      {error && <p className="text-small orange-font">{helperText}</p>}
      {fileTypeError && <p className="text-small orange-font">*{fileTypeError}</p>}
    </div>
  );

}