import React from "react";
import { styled, Typography, Button, Box } from "@mui/material";
import CustomButton from "../../Common/Button/Button.component"
import "./Overly.styles.css"
export default function Overlay(props) {


    return (
        <div>
            <h1 className={`${props.signIn ? "emerald-font" : "orange-font"} heading-large`} >{props.greeting}</h1>
            <p className="text-standard black-font" paragraph>{props.msg}</p>
            {props.userType === "resident" && !props.signIn &&
                <div className="resident-info flex-cols-wrap">
                    <p className="text-standard grey-font">Login to manage your journey, all at your own pace.</p>
                    <p className="text-standard grey-font"> We promise no spam.</p>
                </div>
            }
        </div>
    );
};

