import "./AppLayout.styles.css"
import React, { useState } from "react";
import {useAuth0} from "@auth0/auth0-react";
import logo from "../../images/Thermly-Orange.svg"
import Grid from "@mui/material/Grid";

export default function AppFooter (props) {
    const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();

    const badgeStyle = {
        '& .MuiBadge-badge': {
            color: 'white',
            backgroundColor: '#08979C',
        },
    };

    return (
      <>
        <div className="app-footer">
          <div className="app-footer-left">
            <span className="footer-logo">
              <img src={logo} alt="logo" width="80px" />
            </span>
          </div>
          <div className="text-small app-footer-right">
            <Grid container>
              <Grid>
                <a
                  className="text-standard black-font"
                  target="_blank"
                  href="/Thermly%20Platform%20-%20User%20Terms.pdf">
                  Terms & Conditions
                </a>
              </Grid>
              {/* <Grid >
                                <a className="text-standard black-font">Cookie Policy</a>
                            </Grid> */}
              <Grid>
                <p className="text-standard grey-font">
                  © Thermly Limited (2024). All rights reserved.
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
};