import { Form, Formik } from 'formik';
import CustomButton from '../Button/Button.component';
import Input from './Input';
import { dynamicValidation, transformValidationSchema } from '../../../utils/YupValidations';
import Alert from '../Alerts/Alert.component';
import './form.component.style.css';
import { Divider } from '@mui/material';

export default function CustomForm({
  handleSubmit,
  labelStyles,
  autoSave,
  formData,
  formKey,
  buttonLabel,
  inputStyles,
  button_position_css,
  small,
  installer,
  noLines,
  form_disabled,
  handleAutoSave,
  detailedDataCapture,
  buttonExtraClasses,
  buttonIcon,
  buttonCssType
}) {
  // Create a key based on the `formData` prop
  let formId = formKey
    ? formKey
    : formData && formData.length > 0 && formData.map((question) => question.id).join('-');

  // Initialize form values based on the questions data
  const initialValues =
    formData &&
    formData.reduce((acc, question) => {
      const defaultValue = question.question_type === 'photo' ? [] : '';
      acc[question.id] = question.value || defaultValue;
      return acc;
    }, {});
  // Generate dynamic validation schema based on the questions data
  const validationSchema = dynamicValidation(
    formData &&
      formData.length > 0 &&
      formData.reduce((acc, question) => {
        acc[question.id] = transformValidationSchema(question);
        return acc;
      }, {})
  );
  console.log(formData, 'question');
  // Render the form using Formik
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        key={formId}
        onSubmit={handleSubmit}>
        {(formikProps) => {
          // handeling auto save
          const handleInputChange = (id, value) => {
            if (autoSave) {
              const childQuestions =
                formData && formData.filter((question) => question.parent_id === id);
              // Determine if the value has changed
              const valueChanged = formikProps.values[id] !== value;
              if (valueChanged) {
                // Clear the values of child questions if parent response changes
                childQuestions.forEach((child) => {
                  if (formikProps.values[child.id]) {
                    formikProps.setFieldValue(child.id, '');
                    handleAutoSave(child.id, '');
                  }
                });

                handleAutoSave(id, value);
              }
            } else {
              formikProps.setFieldValue(id, value);
            }
          };

          const filteredQuestions =
            formData &&
            formData.filter(
              (question) =>
                !question.child || formikProps.values[question.parent_id] === question.parent_value
            );
          return (
            <Form className={`${detailedDataCapture && 'flex-card gap-2'}`}>
              {/* Render each question in the form */}
              {filteredQuestions &&
                filteredQuestions.length > 0 &&
                filteredQuestions &&
                filteredQuestions.map((question) => (
                  <div key={question.id}>
                    <Input
                      id={question.id}
                      autoSave={autoSave}
                      autocomplete={question.autocomplete}
                      type={question.question_type}
                      question={question}
                      form_disabled={form_disabled}
                      formikProps={formikProps}
                      labelStyles={labelStyles}
                      inputStyles={inputStyles}
                      installer={installer}
                      onInputChange={handleInputChange} // Pass the onInputChange function to the Input component
                      detailedDataCapture={detailedDataCapture}
                    />
                    {!noLines && <Divider />}
                  </div>
                ))}
              {/* Render an error message if the form is invalid */}
              {formikProps.submitCount > 0 &&
                !formikProps.isValid &&
                filteredQuestions.length > 1 && (
                  <div style={{ maxWidth: '100%', marginTop: '20px' }}>
                    <Alert type="error" message="Please complete all the required fields" />
                  </div>
                )}
              {/* Render a submit button */}
              <div className={button_position_css ? button_position_css : ''}>
                <CustomButton
                  small={small ? small : false}
                  type="submit"
                  variant="contained"
                  cssType={buttonCssType ? buttonCssType : 'secondary'}
                  disabled={form_disabled ? form_disabled : false}
                  label={buttonLabel ? buttonLabel : 'Continue'}
                  extraClasses={buttonExtraClasses}
                  icon={buttonIcon}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
