//Third Part Imports
import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import { post, getSingle } from "../../../services/API";
import CustomForm from "../../Common/Forms/form.component";
import Alert from "../../Common/Alerts/Alert.component";
import useAuth from "../../../auth/AuthProvider";

const LoginFormData = [
    {
        "id": "email",
        "question_number": "1",
        "label": "Email Address",
        "question_description": "",
        "question_type": "text",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "email",
                "validation_parameter": "",
                "validation_message": "Please enter a valid email address"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your email"
            }
        ],
        "image": "",
        "value": ""
    },
    {
        "id": "password",
        "question_number": "2",
        "label": "Password",
        "question_description": "",
        "question_type": "password",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "password",
                "validation_parameter": "",
                "validation_message": "Please enter a valid password"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your  password"
            }
        ],
        "image": "",
        "value": ""
    }
]
const passwordChange = [
    {
        "id": "email",
        "question_number": "1",
        "label": "Email Address",
        "question_description": "",
        "question_type": "text",
        "data_type": "text",
        "child": false,
        "parent_id": null,
        "parent_value": null,
        "disabled": false,
        "autocomplete": false,
        "thermly": false,
        "thermly_text": "",
        "thermly_image": null,
        "options": null,
        "validation": [
            {
                "validation_id": 78,
                "validation_type": "email",
                "validation_parameter": "",
                "validation_message": "Please enter a valid email address"
            },
            {
                "validation_id": 79,
                "validation_type": "required",
                "validation_parameter": "",
                "validation_message": "Please enter your email"
            }
        ],
        "image": "",
        "value": ""
    },
]
export default function LoginForm({ userType, setError, setErrorMessage, forgot_password, setForgotPassword, setAlertMessage, passwordChanged, setpasswordChanged, setResendVerification, setSignUPformData, setCountdown }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const { user, error, login, signUp, logout } = useAuth();
    const intendedDestination = location.state && location.state.pathname || (userType === "resident" ? "/resident/resident-home" : "/installer/home");


    const submitHandler = async (values) => {
        setLoading(true)
        setCountdown(20)
        setResendVerification(false)
        setAlertMessage("")
        setpasswordChanged(false)
        const onSuccess = (message, type) => {
            setLoading(false)
            setAlertMessage("Login Successful", "success")
            setError(false);
            setErrorMessage("");
            navigate(intendedDestination)

        };

        const onError = (errorMessage, code) => {
            setError(true)
            setErrorMessage(errorMessage);
            if (code == 'not_verified') {
                setCountdown(0)
                setResendVerification(true)
            }
            setSignUPformData([values])
        };
        login(userType, values, onSuccess, onError)
        setLoading(false)

    };
    const requestResetPassword = async (values) => {
        setLoading(true)
        setError(false);
        setErrorMessage("");
        const obj = { ...values, "user_type": userType }
        try {
            const res = await post("auth", "requestResetPassword", obj);
            if (res.data) {
                setLoading(false)
                setAlertMessage("Password reset link sent to your email. Change your password and sign In.")
                setForgotPassword(false)
                setError(false);
                setErrorMessage("");

            }
            if (res.error) {
                setError(true);
                setAlertMessage("");
                setErrorMessage(res.error.message);
                setLoading(false)
            }
        } catch (error) {
            console.error("An error occurred:", error);
            setLoading(false)
        }
    }
    useEffect(() => {
        if (forgot_password) {
            setForgotPassword(true)
            setError(false);
            setErrorMessage("");
            setAlertMessage("")
        }
        if (passwordChanged) {
            setAlertMessage("Your password has been changed. Please log in below.", "success")

        }
    }, [forgot_password]);

    return (
        <>

            <CustomForm autoSave={false} formData={forgot_password ? passwordChange : LoginFormData}
                small={true}
                noLines={true}
                button_position_css="flex-row-wrap justify-center"
                handleSubmit={forgot_password ? requestResetPassword : submitHandler}
                labelStyles="text-standard  emerald-font"
                inputStyles="var(--emerald-color)"
                buttonLabel={forgot_password ? "Request" : "Sign in"}
                form_disabled={loading}
            />
            {!forgot_password && <a onClick={() => {
                setForgotPassword(true)
                setpasswordChanged(false)
            }
            } className="text-standard emerald-font" style={{ color: "var(--emerald-color)", textDecoration: "underline", cursor: "pointer" }}>
                Forgot password?
            </a>}
            {loading && <CircularProgress color="secondary" />}
        </>
    );
};
